<template>
  <div>
    <nav-bar />
    <div class="member-header-wrap">
      <div class="member-header">
        <div class="member-header-left">
          <img class="member-logo" :src="$store.getters.getSiteInfo.logal" />
          <div>
            <p>{{ username }}</p>
            <p>{{ userInfo.name }}</p>
            <p>已充值：{{ userInfo.total_recharge_money }}</p>
          </div>
        </div>
        <div class="member-header-right">
          <p>会员专属特权</p>
          <div>
            <div>
              <img class="kefuPng" src="../../assets/images/kefu.png" />
              <p>专属客服</p>
            </div>
          </div>
        </div>
      </div>

      <div class="member-content">
        <el-row style="display: flex;">
          <el-col :class="ind <= 1 ? 'listCol' : 'hoverContent listCol'" :span="Math.floor(24 / memberList.length)" v-for="(ite, ind) in memberList" :key="ind">
            <el-row class="listRow" v-for="(item, index) of memberListBase" :key="index">
              <div v-if="ind != 0">
                <div class="flexBun">
                  <span v-if="index == 'la'">
                    {{ ite[index] }}
                  </span>
                  <div class="toRechageClass" @click="toAddRechange()" v-if="userInfo.top_up_amount < ite['lc'] && index === 'la'">立即开通</div>
                </div>
                <span v-if="index === 'lb'">每单优惠{{ ite[index] }}元</span>
                <span v-if="index === 'lc'" style="color: red">累计充值{{ ite[index] }}元</span>
                <span v-if="index === 'ld' || index === 'lf'"><i class="el-icon-success iconSelelct"></i></span>
                <span v-if="index === 'le'"><i :style="ite[index] === false ? 'opacity: 0' : 'opacity: 1'" class="el-icon-success iconSelelct"></i></span>
                <span v-if="index === 'lg'"><i :style="ite[index] === false ? 'opacity: 0' : 'opacity: 1'" class="el-icon-success iconSelelct"></i></span>
              </div>
              <span v-else :style="index == 'lc' ? 'color: red;' : ''">{{ ite[index] }}</span>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import { myMemberInfo, allMemberLv } from 'network/api'
export default {
  components: {
    NavBar
  },
  data () {
    return {
      userInfo: {},
      memberListBase: {
        la: '功能特权',
        lb: '优惠金额',
        lc: '免费升级',
        ld: '专属客服',
        le: '专属运营导师',
        lf: '投诉建议',
        lg: '新功能内侧'
      },
      memberList: []
    }
  },
  computed: {
    // 获取用户信息
    username () {
      return this.$store.getters.getUserInfo.username
    }
  },
  mounted () {
    this.getMemberinfo()
    this.getMemberLv()
  },
  methods: {
    getMemberinfo () {
      myMemberInfo().then((res) => {
        this.userInfo = res.data
      })
    },
    getMemberLv () {
      allMemberLv({ key: this.$configList[window.location.host] }).then((res) => {
        console.log(res)
        if (res.code === 1) {
          const arr = []
          res.data.map((item, index) => {
            arr.push({
              la: item.name,
              lb: item.discount,
              lc: item.top_up_amount,
              ld: true,
              le: index !== 0,
              lf: true,
              lg: index !== 0
            })
          })
          this.memberList = [this.memberListBase, ...arr]
        }
      })
    },
    toAddRechange () {
      this.$router.push('/account/balance')
    }
  }
}
</script>

<style scoped>
.member-header-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.member-header {
  width: 90%;
  height: 200px;
  background: url('../../assets/images/header-bg.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.member-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.member-header-left {
  display: flex;
  align-items: center;
  color: #fff;
}
.member-header-right {
  display: flex;
  align-items: center;
  color: #fff;
  flex-direction: column;
}
.kefuPng {
  width: 50px;
  height: 50px;
}
.member-content {
  width: 80%;
  margin-top: 50px;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}
.iconSelelct {
  color: #409eff;
  font-size: 18px;
}
.member-content .listRow:nth-child(2n) {
  background: hsl(0deg 0% 98%);
}
.member-content .listRow:nth-child(1) {
  height: 70px;
  line-height: 35px;
  font-size: 18px;
}
.listRow {
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.listCol {
  text-align: center;
  border-right: 1px solid #eee;
  flex: 1;
}
.hoverContent:hover {
  transform: scale(1.1);
  background: #fff9e9 !important;
  border: 1px solid #e8d2a9;
  box-shadow: 0 0 1px 1px #fff3ca;
  z-index: 100;
}

.toRechageClass{
  font-size: 13px;
  height: 30px;
  width: 100px;
  background: #f9d277;
  border-radius: 15px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.flexBun{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
